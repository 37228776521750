import Service from "./_service";

const resourse = "/api/event/notifications";
export default {
    get(payload) {
        return Service.get(`${resourse}`, payload);
    },

    deleteOrReadNotification(payload, header) {
        return Service.put(`${resourse}`, payload, { headers: header });
    }
};