/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import AppHome from "@/views/app-home.vue";
//import AppEventDetail from "@/views/app-event-detail.vue";
//import AppUpcomingEvents from "@/views/app-upcoming-events.vue";
//import AppNotification from "../views/app-notification.vue";
import NotFound from "@/views/not-found.vue";
import AppMain from "@/views/app-main.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/event-detail/:eventName",
    name: "event-detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "desktop" */ "../views/event-detail.vue")
  },
  {
    path: "/upcoming-events",
    name: "upcoming-events",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "desktop" */ "../views/upcoming-events.vue")
  },
  {
    path: "/test-api",
    name: "test-api",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "desktop" */ "../views/test-api.vue")
  },
  {
    path: "/demo-map-leaflet",
    name: "demo-map-leaflet",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "desktop" */ "../views/demo-map-leaflet.vue")
  },
  {
    path: "/application",
    component: AppMain,

    children: [
      {
        path: '',
        component: AppHome
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /app/app-upcoming-events is matched
        
        path: 'app-upcoming-events',
        
        component: () =>
          import(/* webpackChunkName: "application" */ "../views/app-upcoming-events.vue")
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /app/app-event-detail is matched
        name:'appEventDetail',
        path: 'app-event-detail/:eventName',
        //redirect:'/application/app-event-detail',
        component: () =>
          import(/* webpackChunkName: "application" */ "../views/app-event-detail.vue")
      },
      {
        path: "app-notification",
        name: "app-notification",
        component: () =>
          import(/* webpackChunkName: "application" */ "../views/app-notification.vue")
      },
      {
        path: "app-favourite",
        name: "app-favourite",
        component: () =>
          import(/* webpackChunkName: "application" */ "../views/app-favourite.vue")
      },
      {
        path: "test-lazy-load",
        name: "test-lazy-load",
        component: () =>
          import(/* webpackChunkName: "application" */ "../components/testLazyLoadComponent.vue")
      }
    ]
  },
  {
    path: "**",
    component:NotFound
  }
];

const router = new VueRouter({
  // mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;