<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <header>
      <div class="header-container">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <div class="header-left">
                <router-link to="Home">
                  <img src="@/assets/images/logo.png" />
                </router-link>
                <h2>NUS</h2>
                <p>National University of Singapore</p>
              </div>
            </div>
            <div class="col-6">
              <div class="header-right">
                <div class="topnav">
                  <a class="active header-item" href="#events">
                    <img src="@/assets/images/event-icon.png" />
                  </a>
                  <div class="dropdown header-item">
                    <button class="dropdown-toggle" data-toggle="dropdown" type="button">
                      <img src="@/assets/images/notification-icon.png" />
                    </button>
                  </div>
                  <a class="header-item" href="#favourites">
                    <img src="@/assets/images/fav-icon.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <section class="homepage">
      <div class="main-content">
        <div class="container">
            <div class="banner-container">
        

          <div class="center-block">
            <img src="@/assets/images/nus-center-image.jpg" />
         </div>
          <div class="splash-center-block"> </div>
          <div class="splash-screen-text">
            <h1 class="main-title">Life Sciences Institute Events App</h1>
          </div>
           <div class="splash-screen-copyright-text">
            <h6 class="copyright">Copyright © 2020</h6>
          </div>
        </div>
        </div>
      
      </div>
    </section>
  </div>
</template>

 <script>
import router from "../router";

export default {
  data: function() {
    setTimeout(this.navigate, 3000);
    return {
      none: 0
    };
  },
  methods: {
    navigate() {
      return router.push({ path: "upcoming-events" });
    }
  }
};

// // @ is an alias to /src
// // import HelloWorld from "@/components/HelloWorld.vue";

// // export default {
// //   name: "home",
// //   components: {
// //     HelloWorld
// //   }
// // };
// //var delay = 9000;
// //var url = "/upcoming-events";
// // setTimeout(function() {
// //   window.location = url;
// // }, 5000);
</script>
