import axios from 'axios'

//const baseDomain = "https://nuslsi-admin.dev2ezasia.com";
// const baseDomain = "http://ec2-52-76-174-231.ap-southeast-1.compute.amazonaws.com";
const baseDomain ="https://events.cybercomcreation.com/public";
//const baseDomain = " https://lsi.nus.edu.sg/eventapp/nusadmin/public";

//const test = "https://api.myjson.com/bins";
//const baseDomain = "//hn.algolia.com/api";
//const baseDomain = "http://ccczend:1339";
const baseURL = `${baseDomain}`;
// const clientID = "55f6e776-57c4-44df-8843-ce2e87f219de";
// const clientSecret = "z8SKsjVstUAkA4g80mtJKyUfzd4Q961lLykJ3MdQa";
// const grantType = "client_credentials";
//const data;
// const testLazyLoad = "//hn.algolia.com/api";
// const baseURL = `${testLazyLoad}`;

export default axios.create({
    baseURL
});

