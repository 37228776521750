import axios from 'axios';
import Service from "./_service";
const resourse = "/api/event/settings";
export default {

    //for one map api
    // getLocation(searchLocation) {

    //     return axios.get("https://developers.onemap.sg/commonapi/search?searchVal="+searchLocation+'&returnGeom=Y&getAddrDetails=N&pageNum=1').then(data=>{return data});
    //     //return axios.get("https://developers.onemap.sg/commonapi/search?searchVal=nus&returnGeom=Y&getAddrDetails=N&pageNum=1").then(data => { return data });
    // }

    getConfigData(payload){
       
        return Service.get(`${resourse}`,payload).then(data => { return data });
    }
}