<template>
  <!-- <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link>|
      <router-link to="/about">About</router-link>
    </div>
    <router-view />
  </div>-->
  <div id="app">
    <header id="nav"></header>
    <router-view />
  </div>
</template>

<style lang="scss">
// #app {
//   font-family: "Avenir", Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>

<style>
@import "./assets/styles/bootstrap.min.css";
@import "./assets/styles/style.css";
</style>

<script>
export default {
  created() {
    this.iosX = false;
  },
  mounted() {
    // let script = document.createElement("script");
    // // script.setAttribute("src", "https://code.jquery.com/jquery-3.4.1.min.js");
    // script.setAttribute("src", "https://maps.googleapis.com/maps/api/js?key=AIzaSyD8myc0STEz1cOvUZ7gEXRYJ8oFekIfSdM");
    // document.head.appendChild(script);

    // var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // // Get the device pixel ratio
    // var ratio = window.devicePixelRatio || 1;

    // // Define the users device screen dimensions
    // var screen = {
    //   width: window.screen.width * ratio,
    //   height: window.screen.height * ratio
    // };

    // // iPhone X Detection
    // if (iOS && screen.width == 1125 && screen.height === 2436) {
    //   this.iosX = true;
    // } else {
    //   this.iosX = false;
    // }
  }
};
</script>
