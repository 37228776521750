import Service from "./_service";
const resourse = "/api/event/favorites";

const auth = ""
export default {
    get(payload) {
        return Service.get(`${resourse}`, payload);
    },
    isFavourite(payload,header) {
        return Service.post(`${resourse}`, payload, {
            headers: header
        });
    }
};