import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import testAPIComponent from "./components/testAPIComponent";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'
var SocialSharing = require('vue-social-sharing');
Vue.config.productionTip = false;
delete L.Icon.Default.prototype._getIconUrl  
// eslint-disable-next-line  
L.Icon.Default.mergeOptions({  
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
  iconUrl: require('leaflet/dist/images/marker-icon.png'),  
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')  
})
Vue.component("test-component", testAPIComponent);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

Vue.use(SocialSharing);