// import Service from "./_service";
// const resourse = "/oauth/token";
// export default {
//     post(payload) {
//         return Service.post(`${resourse}`, payload);
//     }
// };
//const clientID: "55f6e776-57c4-44df-8843-ce2e87f219de",
//clientSecret: "z8SKsjVstUAkA4g80mtJKyUfzd4Q961lLykJ3MdQaZ",
//grantType: "client_credentials",

import axios from "axios";
export default {
    getToken() {
        //return axios.post("http://nus/oauth/token", {
        //return axios.post("https://lsi.nus.edu.sg/eventapp/nusadmin/public/oauth/token", {
        // return axios.post("http://ec2-52-76-174-231.ap-southeast-1.compute.amazonaws.com/oauth/token", {
        return axios.post("https://events.cybercomcreation.com/public/oauth/token", {
            client_id: "55f6e776-57c4-44df-8843-ce2e87f219de",
            client_secret: "z8SKsjVstUAkA4g80mtJKyUfzd4Q961lLykJ3MdQaZ",
            grant_type: "client_credentials"
        }).then(({ data }) => {
            //eslint-disable-next-line
            return data;
        }).catch(function (error) {
            // eslint-disable-next-line
            throw error.response;
        });
    }
}