import HomeService from './homeService';
import TestService from './testService';
import EventDetailService from './eventDetailService';
import GetTokenService from './getTokenService';
import LocationService from './locationService';
import FavouriteService from './favouriteService';
import NotificationService from './notificationService';
const services = {
    home: HomeService,
    testLazyLoad: TestService,
    eventDetail: EventDetailService,
    getToken: GetTokenService,
    getLocation: LocationService,
    getFavouriteEvents: FavouriteService,
    getNotification: NotificationService
}

export const ServiceFactory = {
    get: name => services[name]
};