<template>
  <div>
    <ul>
      <li v-for="item in posts" v-bind:key="item.id">{{item.Title}}</li>
    </ul>
  </div>
</template>

<script>
import { ServiceFactory } from "../services/_serviceFactory";
const HomeService = ServiceFactory.get("home");

export default {
  name: "test-component",
  data() {
    return {
      posts: []
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      /*----------- method - 1 --------------*/

      //   const { data } = await HomeService.get();
      //   this.posts = data;
      //   // eslint-disable-next-line
      //   console.log(this.posts);

      /*----------- method - 2 --------------*/
      await HomeService.get().then(response => {
        // eslint-disable-next-line
      
        this.posts = response.data;
      });
    }
  }
};
</script>
